// utils
import React, { useState } from "react";
import { isSafari as isSafariImport } from "react-device-detect";
import { isMobile as isMobileImport } from "react-device-detect";
import NProgress from "nprogress";
import Router, { useRouter } from "next/router";
import { SnackbarProvider } from "notistack";

// TODO work in progress to get bootstrap out of the stack
import "nprogress/nprogress.css";
import "public/fonts/styles.css";
import { Provider, useStore } from "outstated";
import dynamic from "next/dynamic";
import { useEffect } from "react";
// mui
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider } from "@emotion/react";
import theme from "components/global/theme";
import createEmotionCache from "components/global/createEmotionCache";
import { LicenseInfo } from "@mui/x-license-pro";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
//components
import CookieConsentBanner from "components/Header/newComponents/CookieConsentBanner";

const Header = dynamic(() => import("components/Header/index"));
const Footer = dynamic(() => import("components/Footer/index"));

NProgress.configure({ showSpinner: false });

Router.onRouteChangeStart = () => {
  NProgress.start();
};

Router.onRouteChangeComplete = () => {
  NProgress.done();
};

Router.onRouteChangeError = () => {
  NProgress.done();
};

// init firebase app
import { initializeApp, getApps } from "firebase/app";
import { firebaseConfig } from "components/Firebase/firebaseConfig";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

export const AppStore = () => {
  let firebaseApp, firebaseAppAuth, firebaseAppStorage;
  if (!getApps().length) {
    firebaseApp = initializeApp(firebaseConfig);
    firebaseAppAuth = getAuth(firebaseApp);
    firebaseAppStorage = getStorage(firebaseApp);
  }

  // real
  const [address, setAddress] = useState("");
  const [listPrice, setListPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [user, setUser] = useState();
  const [password, setPassword] = useState("");

  const [confirmationCode, setConfirmationCode] = useState("");
  const [userListingsData, setUserListingsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gmapsLoaded, setGmapsLoaded] = useState(false);
  const [listerType, setListerType] = useState("");
  const [addressInstantOffer, setAddressInstantOffer] = useState("");
  const [phoneNumberInstantOffer, setPhoneNumberInstantOffer] = useState("");
  const [name, setName] = useState("");
  const [sharedOutputObject, setSharedOutputObject] = useState({});
  const [isMobile, setIsMobile] = useState(isMobileImport);
  const [isSafari, setIsSafari] = useState(isSafariImport);
  const [addressFrontPage, setAddressFrontPage] = useState("");
  const [layoutVisible, setLayoutVisible] = useState(true);
  const [editing, setEditing] = useState(false);
  const [currentPageData, setCurrentPageData] = useState({});
  const [rotatedImagesData, setRotatedImagesData] = useState({});
  const [imageUploadRespData, setImageUploadRespData] = useState([]);
  const [formAddress, setFormAddress] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [surveyChainType, setSurveyChainType] = useState("");
  // survey data objects
  // local surveyDataObj is used for currently being filled out surveys
  // it must be reset at end of each survey to prevent data bleed
  const [localSurveyDataObj, setLocalSurveyDataObj] = useState({});
  const [localSurveyChain, setLocalSurveyChain] = useState([]);
  const [currentLocalSurveyStrapiID, setCurrentLocalSurveyStrapiID] =
    useState("");
  const [userProfile, setUserProfile] = useState({});
  // tours data
  const [scheduledToursData, setScheduledToursData] = useState([]);
  const [unredeemedTokens, setUnredeemedTokens] = useState(0);
  const [redeemedTokens, setRedeemedTokens] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [homeListings, setHomeListings] = useState({});
  const [dealManagerTours, setDealManagerTours] = useState([]);
  const [buyerTours, setBuyerTours] = useState([]);
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    options: {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    },
  });
  const [showChat, setShowChat] = useState(false);
  const [loanAnswersForChat, setLoanAnswersForChat] = useState({});
  const [currentLoan, setCurrentLoan] = useState({});
  const [surveyFinishedSuccess, setSurveyFinishedSuccess] = useState(false);
  // const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  // setIsMobile(smallScreen);
  const [adminTableRows, setAdminTableRows] = useState([]);
  const [userLoans, setUserLoans] = useState(false);
  const [adminLoans, setAdminLoans] = useState([]);
  const [isNewUser, setIsNewUser] = useState(false);
  // insurance
  const [insurancePolicies, setInsurancePolicies] = useState(false);
  const [adminInsurancePolicies, setAdminInsurancePolicies] = useState([]);
  // firebase util
  const [firebase, setFirebase] = useState(firebaseApp);
  const [firebaseAuth, setFirebaseAuth] = useState(firebaseAppAuth);
  const [firebaseStorage, setFirebaseStorage] = useState(firebaseAppStorage);
  // claims and permissions
  const [userPermissions, setUserPermissions] = useState({});
  const [userRoles, setUserRoles] = useState([]);
  const [userRolesForInvite, setUserRolesForInvite] = useState([]);
  //
  const [activeLoans, setActiveLoans] = useState([]);
  const [freezeWholePageScroll, setFreezeWholePageScroll] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  // centralized displays
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [progressBarCounter, setProgressBarCounter] = useState(0);
  const [prompToSignIn, setPrompToSignIn] = useState(false);
  const [promptStatusText, setPromptStatusText] = useState("");
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

  // admin loan
  const [showAdminChat, setShowAdminChat] = useState(false);

  const [loanSectionController, setLoanSectionController] = useState({});
  const [showUploadLogSection, setShowUploadLogSection] = useState(false);
  const [showProcessing, setShowProcessing] = useState([]);

  return {
    showProcessing,
    setShowProcessing,
    showUploadLogSection,
    setShowUploadLogSection,
    loanSectionController,
    setLoanSectionController,
    isAuthModalOpen,
    setIsAuthModalOpen,
    prompToSignIn,
    setPrompToSignIn,
    promptStatusText,
    setPromptStatusText,
    address,
    setAddress,
    listPrice,
    setListPrice,
    description,
    setDescription,
    email,
    setEmail,
    user,
    setUser,
    password,
    setPassword,
    confirmationCode,
    setConfirmationCode,
    userListingsData,
    setUserListingsData,
    loading,
    setLoading,
    gmapsLoaded,
    setGmapsLoaded,
    listerType,
    setListerType,
    addressInstantOffer,
    setAddressInstantOffer,
    phoneNumberInstantOffer,
    setPhoneNumberInstantOffer,
    name,
    setName,
    sharedOutputObject,
    setSharedOutputObject,
    isMobile,
    setIsMobile,
    isSafari,
    setIsSafari,
    addressFrontPage,
    setAddressFrontPage,
    layoutVisible,
    setLayoutVisible,
    editing,
    setEditing,
    currentPageData,
    setCurrentPageData,
    rotatedImagesData,
    setRotatedImagesData,
    imageUploadRespData,
    setImageUploadRespData,
    formAddress,
    setFormAddress,
    selectedPlan,
    setSelectedPlan,
    surveyChainType,
    setSurveyChainType,
    localSurveyDataObj,
    setLocalSurveyDataObj,
    localSurveyChain,
    setLocalSurveyChain,
    userProfile,
    setUserProfile,
    scheduledToursData,
    setScheduledToursData,
    currentLocalSurveyStrapiID,
    setCurrentLocalSurveyStrapiID,
    unredeemedTokens,
    setUnredeemedTokens,
    redeemedTokens,
    setRedeemedTokens,
    isAdmin,
    setIsAdmin,
    homeListings,
    setHomeListings,
    dealManagerTours,
    setDealManagerTours,
    buyerTours,
    setBuyerTours,
    snackbarData,
    setSnackbarData,
    showChat,
    setShowChat,
    loanAnswersForChat,
    setLoanAnswersForChat,
    currentLoan,
    setCurrentLoan,
    surveyFinishedSuccess,
    setSurveyFinishedSuccess,
    adminTableRows,
    setAdminTableRows,
    userLoans,
    setUserLoans,
    adminLoans,
    setAdminLoans,
    isNewUser,
    setIsNewUser,
    insurancePolicies, // new version of userLoans for insurance policies
    setInsurancePolicies, // new version of userLoans for insurance policies
    adminInsurancePolicies,
    setAdminInsurancePolicies,
    firebase,
    setFirebase,
    firebaseAuth,
    setFirebaseAuth,
    firebaseStorage,
    setFirebaseStorage,
    userPermissions,
    setUserPermissions,
    userRoles,
    setUserRoles,
    userRolesForInvite,
    setUserRolesForInvite,
    activeLoans,
    setActiveLoans,
    freezeWholePageScroll,
    setFreezeWholePageScroll,
    scrollTop,
    setScrollTop,
    showProgressBar,
    setShowProgressBar,
    progressBarCounter,
    setProgressBarCounter,
    showAdminChat,
    setShowAdminChat,
  };
};

LicenseInfo.setLicenseKey(process.env.MUI_PREMIUM_LICENSE);

export default function MyApp(props) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const router = useRouter();
  // store
  const [globalRestricted, setGlobalRestricted] = useState(false);

  return (
    <Provider stores={[AppStore]}>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            {!globalRestricted ? (
              <Box sx={{ minHeight: "100vh !important" }}>
                <Header setGlobalRestricted={setGlobalRestricted} />
                <Component key={"top_component_wrap"} {...pageProps} />
                {
                  //don't show footer on the map page, weird mobile scroll effect
                  router.pathname !== "/listings" &&
                    !router.pathname.includes("/survey") && <Footer />
                }
                {/* <CookieConsentBanner /> // TODO revive*/}
              </Box>
            ) : (
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100vh",
                }}
              >
                <Box sx={{ margin: "auto" }}>
                  You have violated terms of use of OfferMarket. If you have
                  questions or concerns please email us at hello@offermarket.us
                </Box>
              </Container>
            )}
          </SnackbarProvider>
        </ThemeProvider>
      </CacheProvider>
    </Provider>
  );
}
